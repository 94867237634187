import { useState, useContext, useEffect } from "react";
import { Box, Button, Checkbox, MenuItem, Modal, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { CompanyContext } from "../context/companyContext";

import { Formik, Form, FormikProps } from "formik";
import { complainerDetailsSchema } from "../const/SchemaValidations";
import { InputTextField } from "./Formik Components/InputTextField";
import { InputSelectField } from "./Formik Components/InputSelectField";

interface ComplainerDetailsModalProps {
   open: boolean;
   setOpen: Function;
   formProps: FormikProps<any>;
}

export const ComplainerDetailsModal = (props: ComplainerDetailsModalProps) => {
   const { company } = useContext(CompanyContext);
   const [isChecked, setIsChecked] = useState(false);

   useEffect(() => {
      setIsChecked(false);
   }, []);

   return (
      <Modal
         open={props.open}
         onClose={() => props.setOpen(false)}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box
            bgcolor="white"
            borderRadius={4}
            sx={{
               maxWidth: "90%",
               width: "50%",
               minWidth: 500,
            }}
         >
            <Box px={4} py={2}>
               <Typography variant="h5" fontWeight={600} letterSpacing={1}>
                  Ingresa tu información
               </Typography>
            </Box>
            <Formik
               initialValues={{
                  name: "",
                  lastName: "",
                  gender: "",
                  phoneNumber: "",
                  email: "",
                  address: "",
                  zipCode: "",
                  country: "",
                  state: "",
                  city: "",
               }}
               validationSchema={complainerDetailsSchema}
               onSubmit={(values, actions) => {
                  props.formProps.setValues({
                     ...props.formProps.values,
                     complaintType: "no anonima",
                     pseudonym: `${values.name} ${values.lastName}`,
                     followUpEmail: values.email,
                     complainerDetails: {
                        name: values.name,
                        lastName: values.lastName,
                        gender: values.gender,
                        phoneNumber: values.phoneNumber,
                        email: values.email,
                        address: values.address,
                        zipCode: values.zipCode,
                        country: values.country,
                        state: values.state,
                        city: values.city,
                     },
                  });
                  actions.resetForm();
                  props.setOpen(false);
               }}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box px={4}>
                        <Grid container spacing={1}>
                           <Grid item xs={6}>
                              <InputTextField
                                 required
                                 fullWidth
                                 variant="standard"
                                 id="name"
                                 name="name"
                                 label="Nombre/s"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <InputTextField
                                 required
                                 fullWidth
                                 label="Apellidos"
                                 id="lastName"
                                 name="lastName"
                                 variant="standard"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <InputSelectField
                                 required
                                 fullWidth
                                 label="Género"
                                 id="gender"
                                 name="gender"
                                 variant="standard"
                              >
                                 <MenuItem value={"masculino"}>Masculino</MenuItem>
                                 <MenuItem value={"femenino"}>Femenino</MenuItem>
                                 <MenuItem value={"indefinido"}>Indefinido</MenuItem>
                              </InputSelectField>
                           </Grid>
                           <Grid item xs={6}>
                              <InputTextField
                                 fullWidth
                                 required
                                 label="Celular"
                                 id="phoneNumber"
                                 name="phoneNumber"
                                 variant="standard"
                                 type="number"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 required
                                 fullWidth
                                 label="Correo electrónico"
                                 id="email"
                                 name="email"
                                 variant="standard"
                                 type="email"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 fullWidth
                                 label="Dirección"
                                 id="address"
                                 name="address"
                                 variant="standard"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 fullWidth
                                 label="Código postal"
                                 id="zipCode"
                                 name="zipCode"
                                 variant="standard"
                                 type="number"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 required
                                 fullWidth
                                 label="País"
                                 id="country"
                                 name="country"
                                 variant="standard"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 required
                                 fullWidth
                                 label="Estado"
                                 id="state"
                                 name="state"
                                 variant="standard"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 required
                                 fullWidth
                                 label="Ciudad"
                                 id="city"
                                 name="city"
                                 variant="standard"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Checkbox
                                 data-cy="complainerPrivacyCheck"
                                 size="small"
                                 onChange={() => {
                                    setIsChecked(!isChecked);
                                 }}
                              />
                              He leído y acepto el&nbsp;
                              <Link
                                 target={"_blank"}
                                 to={`/${company.subName}/aviso-de-privacidad`}
                                 style={{ textDecoration: "none" }}
                              >
                                 Aviso de Privacidad
                              </Link>
                           </Grid>
                        </Grid>
                     </Box>
                     <Box
                        bgcolor="#F1F1F1"
                        display="flex"
                        sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}
                     >
                        <Button
                           variant="text"
                           sx={{ flex: 1, py: 2, borderBottomLeftRadius: 16, color: "#d32f2f" }}
                           onClick={() => {
                              props.setOpen(false);
                           }}
                        >
                           Cancelar
                        </Button>
                        <Button
                           sx={{ ml: 1, flex: 1, py: 2, borderBottomRightRadius: 16 }}
                           variant="text"
                           data-cy="saveBtn"
                           type="submit"
                           disabled={!isChecked}
                        >
                           Guardar
                        </Button>
                     </Box>
                  </Form>
               )}
            </Formik>
         </Box>
      </Modal>
   );
};
